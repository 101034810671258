import React from 'react';
//import { Link, graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Head from '../components/head';

const BlogPage = () => {
    return (
        <Layout>
            <Head title="Blog" />
            <div className='blog'>
                <div className='blogs-empty'>
                    <p>Blogs will be displayed soon. <Link to="/">Go Home</Link></p>
                </div>
            </div>
        </Layout>
    );
};

//const BlogPage = () => {
//    const postsList = useStaticQuery(graphql`
//        query {
//            allMarkdownRemark {
//                edges {
//                    node {
//                        frontmatter {
//                            title
//                            date
//                        }
//                        fields {
//                            slug
//                        }
//                    }
//                }
//            }
//        }
//    `);
//    return (
//        <Layout>
//            <div className='blog'>
//                <ol className='posts'>
//                    {
//                        postsList.allMarkdownRemark.edges.map((post, index) => (
//                            <li className='post'>
//                                <Link to={`/blog/${post.node.fields.slug}`}>
//                                    <h2>{post.node.frontmatter.title}</h2>
//                                    <p>{post.node.frontmatter.date}</p>
//                                </Link>
//                            </li>
//                        ))
//                    }
//                </ol>
//            </div>
//        </Layout>
//    );
//};

export default BlogPage;